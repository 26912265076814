<template>
    <div class="login_form">
        <HeaderNav></HeaderNav>
        <div class="box">
            <div class="top">
                <p class="title">{{ $t('common.aboutus') }}</p>
            </div>
            <div class="body">

                <span class="txt">{{ $t('new.aboutUs1') }}</span>
                <br>
                <br>
                <span style="color: aliceblue;font-size: medium;">{{ $t('new.aboutUs2T') }}</span>
                <br>
                <span class="txt">{{ $t('new.aboutUs2') }}</span>
                <br>
                <br>
                <span style="color: aliceblue;font-size: medium;">{{ $t('new.aboutUs3T') }}</span>
                <br>
                <span class="txt">{{ $t('new.aboutUs3') }}</span>
                <br>
                <br>
                <span style="color: aliceblue;font-size: medium;">{{ $t('new.aboutUs4T') }}</span>
                <br>
                <span class="txt">{{ $t('new.aboutUs4') }}</span>
                <br>
                <br>
                <span style="color: aliceblue;font-size: medium;">{{ $t('new.aboutUs5T') }}</span>
                <br>
                <span class="txt">{{ $t('new.aboutUs5') }}</span>
                <br>
                <br>
                <span style="color: aliceblue;font-size: medium;">{{ $t('new.aboutUs6T') }}</span>
                <br>
                <span class="txt">{{ $t('new.aboutUs6') }}</span>
                <br>
                <br>
                <span style="color: aliceblue;font-size: medium;">{{ $t('new.aboutUs7T') }}</span>
                <br>
                <span class="txt">{{ $t('new.aboutUs7') }}</span>
                <br>
                <br>
                <span style="color: aliceblue;font-size: medium;">{{ $t('new.aboutUs8T') }}</span>
                <br>
                <span class="txt">{{ $t('new.aboutUs8') }}</span>
                <br>
                <br>
                <br>

            </div>
            <div style="width: 100%; text-align: right;padding: 20px;">
                
                Ver, 1.10
              

            </div>
        </div>
        <Main-tab-bar></Main-tab-bar>
    </div>
</template>

<script>
import moment from 'moment-timezone'
import HeaderNav from "@/components/HeaderNav.vue";
import MainTabBar from "@/components/tabbar/MainTabBar.vue";

export default {
    components: {MainTabBar, HeaderNav},
    data() {
        return {
            title: ""
        }
    },

    computed: {
        isLogin: function () {
            return this.$store.getters.isLogin;
        },
        userInfo: function () {
            return this.$store.getters.member || localStorage.getItem("MEMBER");
        },
        currentTime() {
            return moment.tz.guess();
        },
    },
    created() {
        // this.title = this.
    }
}

</script>

<style scoped lang="scss">
.login_form {
    background: #0E212E;
    position: relative;
    overflow: hidden;
    text-align: center;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 68px);
}

.box {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    padding: 60px 10px;

    .top {
        width: 96%;
        margin: 10px auto 10px;
        border-radius: 6px;
        //background-color: #243542;
        position: relative;

        .title {
            font-size: 26px;
            font-weight: bold;
            color: #fff;
            padding: 10px;
        }

        .top-img {
            width: 70%;
        }

    }

    .body {
        padding: 0 10px;
        text-align: left;
    }

}

.txt{
    color: #b1bad3;
}

</style>
